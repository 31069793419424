import React from 'react';
import Layout from '../../../layouts/WallisLayout';

import backArrow from '../../../images/black-arrow-left.svg';
import rightArrow from '../../../images/white-arrow-right.svg';
import { assignSingleLocation } from '../../../helpers/locations';

import wallisTransportLogo from '../../../images/wallis-transport.png';
import wallisLubricantsLogo from '../../../images/wallis-lubricants.png';

const JobDescription = ({ pageContext }: any) => {
	pageContext = assignSingleLocation(pageContext);

	// Set lubricants as default
	let logo = wallisLubricantsLogo;
	let href = 'https://wallislubricants.com/';
	let title = 'Wallis Lubricants | Employment Opportunities';
	let desc = 'Wallis Lubricants | Employment Opportunities';
	let backHref = '/lubricants/';

	if (pageContext.brand == 'Wallis Co. Transport') {
		logo = wallisTransportLogo;
		href = 'https://www.wallisco.com/';
		title = 'Wallis Transport | Employment Opportunities';
		desc = 'Wallis Transport | Employment Opportunities';
		backHref = '/transport/';
	}

	return (
		<Layout
			logo={logo}
			href={href}
			title={title}
			desc={desc}
			headerType="dark"
		>
			<section
				className={
					'bg-gradient-to-b from-[#183997] to-[#5192DF] text-white'
				}
			>
				<div className={'container mx-auto py-20 px-6'}>
					<p className="font-hind text-6xl font-bold">
						JOIN THE TEAM
					</p>
				</div>
			</section>

			<section>
				<div className="container mx-auto px-6">
					<div className="w-full max-md:px-[16px]">
						<div className="w-full pt-[48px] pb-[80px] text-left max-md:pb-10 max-md:pt-[32px]">
							<a href={backHref} className="text-xl">
								<button className="flex flex-row items-center !text-black">
									<img
										src={backArrow}
										width={16}
										className="mr-[6px] font-helvetica"
									/>
									Back
								</button>
							</a>
							<h2 className=" text-[42px] font-bold max-md:text-[30px]">
								{pageContext.title}
							</h2>
							<p className="c-[#333333] font-lato text-base font-bold uppercase leading-[28px] max-lg:hidden">
								Location{' '}
								<span className="c-[#333333] pb-[20px] text-base font-normal leading-[28px] max-lg:pb-2">
									{pageContext.fullAddress}
								</span>
							</p>
						</div>
					</div>
					<div className="flex flex-row pb-16 max-lg:flex-col-reverse">
						<div className="flex w-70 flex-col items-start justify-start max-lg:w-full">
							<div className="mb-14 overflow-x-auto pb-4 font-lato max-lg:w-full">
								<p
									dangerouslySetInnerHTML={{
										__html: pageContext.description
									}}
								/>
							</div>
							<a
								className=""
								href={pageContext.url}
								target={'_blank'}
							>
								<button className="group flex h-[44px] w-fit items-center justify-center gap-3 rounded-full border border-white bg-black px-10 font-helvetica text-base font-bold uppercase leading-[28px] text-white transition-all hover:border-black hover:bg-white hover:text-black">
									APPLY NOW
									<div className="relative h-5 w-5">
										<img
											src={backArrow}
											className={
												'absolute rotate-180 transition-all group-hover:opacity-100'
											}
											width={16}
										/>
										<img
											src={rightArrow}
											className={
												'absolute transition-all group-hover:opacity-0'
											}
											width={16}
										/>
									</div>
								</button>
							</a>
						</div>
						<div className="ml-[36px] flex w-30 flex-col items-start justify-start max-lg:ml-0 max-lg:mb-[24px] max-lg:w-full">
							<div className="w-full border-t-4 border-[#3B85F5] bg-[#F1F1F2] px-[24px] py-9">
								<p className="c-[#333333] font-lato text-base font-bold uppercase leading-[28px]">
									Location
								</p>
								<p className="c-[#333333] pb-[20px] font-lato text-base leading-[28px] max-lg:pb-2">
									{pageContext.fullAddress}
								</p>
								<p className="c-[#333333]  font-lato text-base font-bold uppercase leading-[28px]">
									POSTED
								</p>
								<p className="c-[#333333] pb-[20px] font-lato text-base leading-[28px] max-lg:pb-2">
									{pageContext.postingDate}
								</p>

								<div className="w-full pt-[28px] text-center max-lg:text-left">
									<a
										className=""
										href={pageContext.url}
										target={'_blank'}
									>
										<button className="group flex h-[44px] w-fit items-center justify-center gap-3 rounded-full border border-white bg-black px-10 font-helvetica text-base font-bold uppercase leading-[28px] text-white transition-all hover:border-black hover:bg-white hover:text-black">
											APPLY NOW
											<div className="relative h-5 w-5">
												<img
													src={backArrow}
													className={
														'absolute rotate-180 transition-all group-hover:opacity-100'
													}
													width={16}
												/>
												<img
													src={rightArrow}
													className={
														'absolute transition-all group-hover:opacity-0'
													}
													width={16}
												/>
											</div>
										</button>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default JobDescription;
