import React from 'react';
import Layout from '../../../layouts/BriteWorxLayout';

import backArrow from '../../../images/black-arrow-left.svg';

const JobDescription = ({ pageContext }: any) => {
	return (
		<Layout
			title={'Brite WorX | Employment Opportunities'}
			desc={'Brite WorX | Employment Opportunities'}
			headerType="dark"
		>
			<section className={'bg-[#1B5AA1] text-white'}>
				<div className={'container mx-auto py-20 px-6'}>
					<p className="font-hind text-6xl font-bold">
						JOIN THE TEAM
					</p>
				</div>
			</section>

			<section>
				<div className="container mx-auto px-6">
					<div className="w-full max-md:px-[16px]">
						<div className="w-full pt-[48px] pb-[80px] text-left max-md:pb-10 max-md:pt-[32px]">
							<a href="/briteworx/" className="text-xl">
								<button className="flex flex-row items-center !text-black">
									<img
										src={backArrow}
										width={16}
										className="mr-[6px]"
									/>
									Back
								</button>
							</a>
							<h2 className="text-[30px] font-bold text-[#1B5AA1]">
								{pageContext.title}
							</h2>
							<p className="c-[#333333] text-base font-bold uppercase leading-[28px] max-lg:hidden">
								Location{' '}
								<span className="c-[#333333] pb-[20px] text-base font-normal leading-[28px] max-lg:pb-2">
									{pageContext.fullAddress}
								</span>
							</p>
						</div>
					</div>
					<div className="flex flex-row pb-16 max-lg:flex-col-reverse">
						<div className="flex w-70 flex-col items-start justify-start max-lg:w-full">
							<div className="mb-14 overflow-x-auto pb-4 max-lg:w-full">
								<p
									dangerouslySetInnerHTML={{
										__html: pageContext.description
									}}
								/>
							</div>
							<a
								className=""
								href={pageContext.url}
								target={'_blank'}
							>
								<button
									className={
										'rounded-md border border-[#2D5A9C] bg-[#2D5A9C] px-9 py-3 font-roboto-condensed text-xl font-medium text-white transition-all hover:bg-white hover:text-[#2D5A9C]'
									}
								>
									APPLY NOW
								</button>
							</a>
						</div>
						<div className="ml-[36px] flex w-30 flex-col items-start justify-start max-lg:ml-0 max-lg:mb-[24px] max-lg:w-full">
							<div className="w-full border-t-4 border-[#1B5AA1] bg-[#F4EBCB] px-[24px] py-9">
								<p className="c-[#333333]  text-base font-bold uppercase leading-[28px]">
									Location
								</p>
								<p className="c-[#333333] pb-[20px]  text-base leading-[28px] max-lg:pb-2">
									{pageContext.fullAddress}
								</p>
								<p className="c-[#333333]   text-base font-bold uppercase leading-[28px]">
									POSTED
								</p>
								<p className="c-[#333333] pb-[20px]  text-base leading-[28px] max-lg:pb-2">
									{pageContext.postingDate}
								</p>

								<div className="w-full pt-[28px] text-center max-lg:text-left">
									<a
										className=""
										href={pageContext.url}
										target={'_blank'}
									>
										<button
											className={
												'w-full rounded-md border border-[#2D5A9C] bg-[#2D5A9C] px-9 py-3 font-roboto-condensed text-xl font-medium text-white transition-all hover:bg-white hover:text-[#2D5A9C]'
											}
										>
											APPLY NOW
										</button>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default JobDescription;
