import * as React from 'react';

import logo from '../../images/logo-wallis-co.png';

import logoWhite from '../../images/logo-wallis-co-white.png';

interface HeaderProps {
	type: string;
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
	return (
		<header className="relative z-[100] bg-transparent antialiased">
			<div className="container mx-auto flex items-center justify-between bg-transparent px-6 py-5">
				<a href="https://www.wallisco.com/" className="h-full pl-1">
					<img
						className=""
						src={props.type === 'light' ? logoWhite : logo}
						alt="Wallis Co. Logo"
						width={144}
						height={50}
					/>
				</a>
			</div>
		</header>
	);
};

export default Header;
